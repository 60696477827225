import {useEffect} from "react";

import {sfCenterPos} from "../components/_common/_constants";
import {WorkerUrl} from "../constants/workerUrls";
import {Coordinate} from "../store/types";
import {setGeolocation} from "../utils/browser-storage/location";

const setDefaultLatLong = () => {
  setGeolocation(sfCenterPos);
  return sfCenterPos;
};

type CfGeolocateResponse = {
  location: {
    lat: number;
    lng: number;
    regionCode: string | null;
  };
};

const cfGeolocate = async (): Promise<Coordinate> => {
  try {
    const response = await fetch(`${WorkerUrl.PROD}/geolocation`, {
      method: "POST",
    });
    const position = await response.json().then((data: CfGeolocateResponse) => ({
      x: data.location.lat,
      y: data.location.lng,
      regionCode: data.location.regionCode,
    }));

    setGeolocation(position);

    // Asking for browser geolocation after setting the geolocation from the API response
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(({coords: {latitude, longitude}}) => {
        setGeolocation({
          ...position,
          x: latitude,
          y: longitude,
        });
      });
    }

    return position;
  } catch {
    return setDefaultLatLong();
  }
};

export const useGeolocateUser = () => {
  useEffect(() => {
    cfGeolocate();
  }, []);
};
